.App {
  max-width: 960px;
  margin: 0 auto;
  padding: 2em;
}

p {
  line-height: 1.5;
}

iframe {
  border: 0;
  width: min(600px, 100%);
  height: 400px;
  margin: 0 auto;
}

.header {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.photos {
  text-align: center;
  margin-top: 3em;
}

.photos-container {
  display: block;
  margin-bottom: 1em;
}

.photos img {
  max-width: 300px;
  margin-left: 1em;
}

.map {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4em;
}

a.button {
 margin: 0 auto;
 font-size: 20px;
 background: royalblue;
 color: white;
 padding: 0.7em 1em;
 padding-left: 0.9em;
 display: flex;
 align-items: center;
 justify-content: center;
 border: none;
 border-radius: 16px;
 overflow: hidden;
 transition: all 0.2s;
 text-decoration: none;
 width: 10em;
}

a.button span {
 transition: all 0.3s ease-in-out;
}

a.button .eyes {
  margin-left: 0.3em;
}

a.button:hover .eyes {
  transform: scale(1.5) translateX(0.2em);
}

a.button:active {
}

@keyframes fly-1 {
 from {
  transform: translateY(0.1em);
 }

 to {
  transform: translateY(-0.1em);
 }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}


@keyframes App-logo-spin {
  0% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
